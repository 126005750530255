<template>
  <transition name="fade">
    <div
        v-if="isShown"
        class="uk-card uk-card-default uk-card-body uk-margin-small"
>
      Animation
    </div>
  </transition>
</template>

<script>

export default {
  name: "ToggleWithAnimation",
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

</style>
