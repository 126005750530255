import { isFirstOfItsValueInArray } from '../../../../lib/arrayFunctions'

const getExpression = (pattern, flags) => new RegExp(pattern, preparePatternFlags(flags))
const preparePatternFlags = flags => normalizeFlags(enforceFlags(flags))
const normalizeFlags = flags => flags.split('').filter(isFirstOfItsValueInArray).join('')

const enforceFlags = flags => enforceMultilineFlag(enforceUnicodeFlag(flags ?? ''))
const enforceUnicodeFlag = flags => flags.includes('u') ? flags : flags + 'u'
const enforceMultilineFlag = flags => flags.includes('g') || flags.includes('m') ? flags : flags + 'm'

const validRanges = {
  isUrlSafe: '0-9a-zA-Z-._~',
}
const getValidatorExpression = {
  isUrlSafe: flags => getExpression(`^[${validRanges.isUrlSafe}]+$`, flags),
}
const antiPatterns = {
  isUrlSafe: getExpression(`[^${validRanges.isUrlSafe}]`, 'g')
}
export const validators = {
  isUrlSafe: getValidatorExpression.isUrlSafe(),
}

export const validate = (string, validatorName) => validators[validatorName].test(string)
export const validateWithFlags = (string, validatorName, flags) => getValidatorExpression[validatorName](flags).test(string)
export const listConflictingCharacters = (string, rangeName) => [...string.matchAll(antiPatterns[rangeName])].map(m => m[0])
