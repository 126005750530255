<template>
<input
      class="uk-input"
      v-if="editable"
      :autocomplete="autocomplete"
      :name="name"
      :placeholder="placeholder"
      :type="type"
      :value="fieldValue"
      @blur="onInput"
      @keyup="onInput"
      @keyup.esc="revert"
  >
</template>

<script>

import { ref } from 'vue'

export default {
  name: 'VTextInput',
  emits: ['cancel', 'change', 'confirm'],
  props: {
    autocomplete: { // https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofilling-form-controls%3A-the-autocomplete-attribute
      type: String,
    },
    editable: {
      type: Boolean,
      default: true
    },
    name: String,
    passwordVisible: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Name'
    },
    type: {
      type: String,
      default: 'text'
    },
    value: String,
  },
  setup(props, { emit }) {
    const fieldValue = ref(props.value)
    const revert = () => {
      fieldValue.value = props.value
      emit('cancel', props.value)
    }
    const onInput = event => {
      fieldValue.value = event.target.value
      const isEnterKey = (event.code || event.key === 'Enter') || (event.keyCode === 13)
      if (isEnterKey) {
        emit('confirm', fieldValue.value)
      }
      emit('change', fieldValue.value)
    }
    return {
      fieldValue,
      onInput,
      revert
    }
  },
}
</script>

<style scoped>

</style>
