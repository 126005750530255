<template>
  <div class="clickToReveal">
    <div class="leftStripe"></div>
    <div class="content">
      <div
          class="icon"
          v-if="showSolution"
      >
        <IconEye
            :size="96"
            fillColor="#516c864c"
        ></IconEye>
      </div>
      <div
          class="clickable"
          @click="revealSolution"
      >
        <div
            class="clickableText"
        >
          <span
              v-if="!showSolution"
              style="color: #516c86!important"
          >Click to reveal</span>
          <span v-else style="color: #516c86!important">Click to hide</span>
        </div>
      </div>
      <div
          class="padded"
          v-if="showSolution"
      >
        <MarkdownContent
            :collectionId="collectionId"
            :documentId="documentId"
            fieldPath="markdownHidden"
            :relayDeleteEvent="true"
            @delete="onDelete"
        ></MarkdownContent>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, ref, watch} from "vue";
import {useCollection} from "@u/serviceAssets/useCollection";
import {useDocument} from "@u/serviceAssets/useDocument";
import {useStore} from "vuex";
import {decode} from "@c/service/document/lib/parse";
// import EditableDiv from "@c/utilities/EditableDiv";
import {useVersion} from "@u/serviceAssets/useVersion";

export default {
  name: "ClickToRevealSolution",
  emits: ['delete'],
  props: {
    collectionId: {
      type: String,
      required: true
    },
    documentId: {
      type: String,
      required: true
    },
    isRevealed: {
      type: Boolean,
      default: false
    },
    relayDeleteEvent: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {
    const store = useStore()
    const {loadCollection} = useCollection()
    const {loadDocument} = useDocument()
    const {patchVersionPayloadAttribute} = useVersion()
    const service = computed(() => store.state.service[store.state.serviceName])
    const collection = computed(() => Object.values(store.state.collection).find(c => c.name === 'paragraphs'))
    const document = computed(() => store.state.document[props.documentId] || Object.values(store.state.document).find(d => d.name === (props.documentName || props[props.documentNameProperty])))

    const payload = computed(() => {
      let payload = {}
      if (document.value?.repoId) payload = decode(document.value?.content, document.value?.encoding)
      return payload
    })

    const markdownClickable = computed(() => payload.value.markdownClickable)
    const markdownHidden = computed(() => payload.value.markdownHidden)

    const showSolution = ref(props.isRevealed)
    const revealSolution = () => showSolution.value = !showSolution.value

    watch(service, isReady => {
      if (isReady) loadCollection({collectionName: 'paragraphs'})
    }, {immediate: true})

    watch(collection, collection => {
      if (collection) loadDocument({
        collectionId: collection.repoId,
        documentId: props.documentId,
      }, 'ClickToReveal.vue')
    }, {immediate: true})

    const updateMarkdownClickable = value => {
      patchVersionPayloadAttribute({
        serviceId: store.state.serviceName,
        collectionId: collection.value.repoId,
        documentId: props.documentId,
        attributeName: 'markdownClickable',
        value
      })
    }

    const onDelete = () => emit('delete', props.documentId)

    return {
      markdownClickable,
      markdownHidden,
      onDelete,
      showSolution,
      revealSolution,
      updateMarkdownClickable,
    }
  }
}
</script>

<style scoped>

.clickToReveal {
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  display: grid;
  grid-template-columns: 10px 1fr;
  background-color: rgb(241, 248, 255);
}

.clickToReveal > :first-child {
  background-color: #516c86;
}

.clickToReveal > .content {
  position: relative;
  z-index: 1;
  width: 100%;
}

.content > .icon {
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  right: 4rem;
  z-index: -1;
}

.padded {
  padding: 1.5rem;
}

.clickable {
  cursor: pointer !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer !important;
}

.clickableText {
  margin: 1rem;
  padding-left: 0.5rem;
  font-weight: bold;
}

</style>
