<template>
  <div class="example">
    <div class="leftStripe"></div>
    <div class="content">
      <div class="icon">
        <IconPill
            :size="96"
            fillColor="#72757e50"
        ></IconPill>
      </div>
      <div class="padded">
        <MarkdownContent
            :collectionId="collectionId"
            :documentId="documentId"
            fieldPath="markdown"
            :relayDeleteEvent="true"
            @delete="onDelete"
        ></MarkdownContent>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, watch} from "vue";
import {useCollection} from "@u/serviceAssets/useCollection";
import {useDocument} from "@u/serviceAssets/useDocument";
import {useStore} from "vuex";
import {useVersion} from "@u/serviceAssets/useVersion";

export default {
  name: "Example",
  emits: ['delete'],
  components: {
    // EditableDiv,
  },
  props: {
    collectionId: {
      type: String,
      required: true
    },
    documentId: {
      type: String,
      required: true
    },
    relayDeleteEvent: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {
    const store = useStore()
    const {loadCollection} = useCollection()
    const {loadDocument} = useDocument()
    const {patchVersionPayloadAttribute} = useVersion()
    const service = computed(() => store.state.service[store.state.serviceName])
    const collection = computed(() => Object.values(store.state.collection).find(c => c.name === 'paragraphs'))

    watch(service, isReady => {
      if (isReady) loadCollection({collectionName: 'paragraphs'})
    }, {immediate: true})

    watch(collection, collection => {
      if (collection) loadDocument({
        collectionId: collection.repoId,
        documentId: props.documentId,
      }, 'Hint.vue')
    }, {immediate: true})

    const updateMarkdown = value => {
      patchVersionPayloadAttribute({
        serviceId: store.state.serviceName,
        collectionId: collection.value.repoId,
        documentId: props.documentId,
        attributeName: 'markdown',
        value
      })
    }

    const onDelete = () => emit('delete', props.documentId)

    return {
      onDelete,
      updateMarkdown,
    }
  }
}
</script>

<style scoped>
.example {
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  background-color: #72757e20;
  display: grid;
  grid-template-columns: 10px 1fr;
}

.example > :first-child {
  background-color: #72757e;
}

.content {
  position: relative;
  z-index: 1;
  width: 100%;
}

.content > .padded {
  padding: 1.5rem;
}

.content > .icon {
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  right: 4rem;
  z-index: -1;
}
</style>
