<template>
  <span
    :class="{ active: hover }"
    class="markdown md-out inline"
    @mouseleave="hover = false"
    @mouseover="hover = true"
  >

    <MarkdownIt
        :value="template"
        :enableEditor="false"
    ></MarkdownIt>

  </span>
</template>

<script>
import { ref }         from 'vue'
import { useMarkdown } from '@/components/use/markdown/useMarkdown'

export default {
  name: 'VMd',
  props: {
    md: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const {
      template
    } = useMarkdown(props)
    const hover = ref(false)
    return {
      hover,
      template,
    }
  },
}
</script>

<style scoped lang="scss">
  .markdown:hover > .anchor {
    opacity: 1 !important;
  }
</style>
