<template>
  <div class="urlSafeStringInput">
    <div class="column p1">
      <div
                v-if="showCharRange"
                class="charRangeInfo message"
      >
        <div>
          Enter a new name. The name will be auto-escaped to be used in links.
          <!-- let user fill out name and avoid (browser-specific) escapes -->
        </div>
      </div>

      <div class="validatedInput column uk-margin">
        <vTextInput
            :disabled="isDisabled"
            :placeholder="placeholder"
            :value="newUrlSafeString"
            @change="validateString"
        />
        <div>
          <span
              v-if="validationErrorMessage"
              class="uk-text-warning validationErrorMessage"
          >
            {{ validationErrorMessage }}
            (<span class="chars">{{ conflictingChars }}</span>)
          </span>
        </div>
      </div>

      <vButton
          v-if="newUrlSafeString"
          :disabled="validationErrorMessage || isDisabled"
          @click="emitNewStringValue"
      >
        <vMd :md="buttonLabelMarkdown"></vMd>
      </vButton>
</div>
  </div>
</template>

<script>
import {computed} from 'vue'
import {useUrlSafeInput} from "@/components/use/useUrlSafeInput";

export default {
  name: 'VUrlSafeStringInput',
  emits: ['change'],
  props: {
    escape: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isConfirmed: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: 'New url-safe string value'
    },
    showCharRange: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, {emit}) {
    const {
      conflictingChars,
      newUrlSafeString,
      validateString,
      validationErrorMessage,
    } = useUrlSafeInput(props)
    return {
      buttonLabelMarkdown: computed(() => `Use name <span class="caseSensitive">${newUrlSafeString.value}</span>`),
      conflictingChars,
      emitNewStringValue: () => emit('change', newUrlSafeString.value),
      newUrlSafeString,
      validateString,
      validationErrorMessage,
    }
  }
}
</script>

<style scoped>

</style>
