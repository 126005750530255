<template>
  <div
      :id="offCanvasId"
      :uk-offcanvas="config"
  >
    <div class="uk-offcanvas-bar">
<button
class="uk-offcanvas-close"
type="button"
uk-close
></button>

      <slot></slot>
</div>
  </div>
</template>

<script>
import {computed} from "vue";

export default {
  name: "VOffCanvas",
  props: {
    offCanvasId: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      default: 'reveal',
      validator: value => ['push', 'reveal', 'slide', 'none'].includes(value)
    }
  },
  setup(props) {
    const config = computed(() => `mode: ${props.mode}; overlay: true`)
    return {
      config
    }
  }
}
</script>

<style scoped>

</style>
