<template>
  <div
      v-if="document && document.id"
  >
    <component
        :is="dataType"
        :collection-id="collectionId"
        :document-id="document.repoId"
        :relayDeleteEvent="relayDeleteEvent"
        @delete="onDelete"
    ></component>
  </div>
</template>

<script>
import ClickToReveal from "@c/utilities/paragraphComponents/ClickToReveal"
import Example from "@c/utilities/paragraphComponents/Example"
import Hint from "@c/utilities/paragraphComponents/Hint"
import MarkdownContent from "@c/utilities/paragraphComponents/MarkdownContent";
import MarkdownColumns from "@c/utilities/paragraphComponents/MarkdownColumns";
import { useDocument } from '@c/use/serviceAssets/useDocument'
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: 'ParagraphsLayout',
  emits: ['delete'],
  components: {
    ClickToReveal,
    Example,
    Hint,
    MarkdownContent,
    MarkdownColumns,
  },
  props: {
    collectionId: {
      type: String,
      required: true,
    },
    documentId: {
      type: String,
    },
    relayDeleteEvent: {}
  },
  setup(props, {emit}) {
    const store = useStore()
    const { getDataType, loadDocument } = useDocument(props)
    if (!props.documentId) loadDocument({
      collectionId: props.collectionId,
      documentId: props.documentId,
    }, 'ParagraphsLayout')
    const document = computed(() => store.state.document[props.documentId])
    const dataType = computed(() => getDataType(props.documentId))

    const onDelete = id => emit('delete', id?.documentId ?? id)

    return {
      document,
      dataType,
      onDelete,
    }
  }
}
</script>

<style scoped>

</style>
