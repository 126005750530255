<template>
  <select
      v-if="options && options.length"
      class="uk-margin"
      v-model="selected"
      @change="onChange"
  >
    <option
disabled
value=""
>
Please select...
</option>
    <option
        v-for="option in options"
        :value="option.text"
        :key="option"
    >
      {{ option.text }}
    </option>
  </select>
</template>

<script>
export default {
  name: "VDropdown",
  props: {
    collectionName: {
      type: String,
      required: true,
    },
    currentValue: {
      type: String,
    }
  },
  data(){
    return {
      selected: null,
    }
  },
  mounted() {
    this.selected = this.currentValue ?? null
  },
  computed: {
    options() {
      return this.$store.state?.[this.collection].map(name => {return {text: name}})
    }
  },
  methods:{
    onChange(event) {
      this.$emit('selection', event.target.value)
    }
  }
}
</script>

<style scoped>

</style>
