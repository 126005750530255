<template>
  <div>
    <div class="uk-grid uk-grid-small uk-child-width-1-2">
      <div class="uk-text-left">
        <a
          :uk-tooltip="abortTooltip"
          :class="{ disabled: abortIsDisabled, visible: abortIsVisible }"
          @click="onAbort"
        >[{{ abortLabel }}]</a>
      </div>
      <div class="uk-text-right">
        <button
          class="uk-button uk-button-default uk-margin-small-right"
          type="button"
          :class="{ visible: commitIsVisible }"
          :disabled="commitIsDisabled"
          :uk-tooltip="commmitTooltip"
          @click="onCommit"
        >{{ commitLabel }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VCommitOrAbort",
  emits: ['abort', 'commit'],
  props: {
    abortIsDisabled: {
      type: Boolean,
      default: false
    },
    abortIsVisible: {
      type: Boolean,
      default: true
    },
    abortLabel: {
      type: String,
      default: 'Cancel'
    },
    abortTooltip: {
      type: String,
      default: 'Cancel this action'
    },
    commitIsDisabled: {
      type: Boolean,
      default: false
    },
    commitIsVisible: {
      type: Boolean,
      default: true
    },
    commitLabel: {
      type: String,
      default: 'OK'
    },
    commmitTooltip: {
      type: String,
      default: 'Confirm'
    }
  },
  methods: {
    onAbort() {
      this.$emit('abort')
    },
    onCommit() {
      this.$emit('commit')
    }
  }
}
</script>

<style scoped>
</style>
