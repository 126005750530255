<template>
  <div>
    <div class="loading uk-text-center">
      Loading {{resourceName}}
    </div>
  </div>
</template>

<script>
export default {
  name: "vLoading",
  props: {
    resourceName: {
      type:String,
      default: 'document'
    }
  }
}
</script>

<style scoped lang="stylus">
  .loading
    font-size 140%
</style>
