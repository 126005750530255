<template>
  <div
      :class="{'uk-padding-remove-right': canEdit}"
      class="version"
  >
    <vOffCanvas
        :off-canvas-id="id_markdownHowTo"
    >
      <vEditingDocumentsInfo></vEditingDocumentsInfo>
    </vOffCanvas>
    <div v-if="markdown !== undefined">
      <article
          :class="{editing: canEdit && showInput}"
          :style="documentStyle"
      >
        <div class="column">
          <vMarkdownInput
              v-if="canEdit && showInput"
              :value="markdown"
              @change="putPayload"
          ></vMarkdownInput>
          <vMarkdown
              class="out"
              :md="markdown"
          ></vMarkdown>
        </div>
        <div
            v-if="canEdit"
            class="uk-iconnav uk-iconnav-vertical"
        >
          <vOffCanvasButton
              :not-a-button="true"
              :off-canvas-id="id_markdownHowTo"
              @click="toggleMarkdownHelp"
          >
            <IconHelpCircle></IconHelpCircle>
          </vOffCanvasButton>
          <div class="toggle">
            <IconLeadPencil
                v-if="!showInput"
                class="primary"
                title="edit content"
                @click="showInput = true"
            ></IconLeadPencil>
            <IconEyeOff
                v-if="showInput"
                class="primary"
                title="hide editor"
                @click="hideEditingComponents"
            ></IconEyeOff>
          </div>
          <div v-if="!noDeleteButton">
            <DeleteDocumentDialog @delete-document-confirmed="onDelete"></DeleteDocumentDialog>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import {useVersion} from '@/components/use/serviceAssets/useVersion'
import DeleteDocumentDialog from "@/components/service/document/DeleteDocumentDialog";
import vEditingDocumentsInfo from '../../service/document/md/vEditingDocumentsInfo'
import vMarkdownInput from "@/components/service/document/md/vMarkdownInput"
import vMarkdown from "@/components/service/document/md/RawMarkdown"

import {computed, ref} from 'vue'
import resolvePath from "@/lib/resolvePath";
import {usePermissions} from "@/components/use/usePermissions";
import {handleError} from "@/lib/handleError";
import {isString} from "../../../../../lib/getVariableType";
import {useStore} from "vuex";

export default {
  name: 'MarkdownContent',
  emits: ['show-delete-dialog', 'delete'],
  components: {
    DeleteDocumentDialog,
    vEditingDocumentsInfo,
    vMarkdown,
    vMarkdownInput,
  },
  props: {
    collectionId: {
      type: String,
      required: true,
    },
    documentId: {
      type: String,
      required: true,
    },
    versionId: {
      type: String,
      default: ''
    },
    fieldPath: {
      type: String,
      default: '',
    },
    noDeleteButton: {
      type: Boolean,
      default: false
    },
    relayDeleteEvent: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {
    const store = useStore()
    const {canEdit} = usePermissions(props)
    const {deleteVersion, getPayload, patchVersionContent, patchVersionPayloadAttribute} = useVersion()
    const payload = computed(() => getPayload(props.documentId))
    const fieldPath = computed(() => {
      let path = ''
      if (props.fieldPath) path = props.fieldPath
          .split('.')
          .filter(item => item)
      return path
    })
    const markdown = computed(() => { // extract to useVersion
      let value
      if (payload.value) {
        try {
          value = resolvePath(payload.value, fieldPath.value)
          if (!isString(value)) value = JSON.stringify(value)
        } catch (err) {
          handleError(`Could not resolve field path ${props.fieldPath}. ${err.message ?? err}`)
        }
      }
      return value
    })

    const putPayload = value => { // extract to useVersion
      if (fieldPath.value) patchVersionPayloadAttribute({
        serviceId: store.state.serviceName,
        collectionId: props.collectionId,
        documentId: props.documentId,
        attributeName: fieldPath.value,
        value
      }) // caution: path !== attributeName
      else patchVersionContent({
        serviceId: store.state.serviceName,
        collectionId: props.collectionId,
        documentId: props.documentId,
        value
      })
    }

    const documentStyle = computed(() => {
      let gridStyle = '1fr'
      if (canEdit.value) {
        gridStyle += ' 2rem'
      }
      return {
        gridTemplateColumns: gridStyle
      }
    })
    const showInfo = ref(false)
    const showInput = ref(false)
    const hideEditingComponents = () => {
      showInfo.value = false
      showInput.value = false
    }
    const id_markdownHowTo = ref('mdHelp')
    const toggleMarkdownHelp = () => {
      showInfo.value = !showInfo.value
    }
    const onDelete = () => {
      const deleteArgs = {
        serviceId: store.state.serviceName,
        collectionId: props.collectionId,
        documentId: props.documentId,
        versionId: props.versionId
      }
      if (props.relayDeleteEvent) emit('delete', deleteArgs)
      else deleteVersion(deleteArgs)
    }
    const emitShowDeleteDialog = () => emit('show-delete-dialog', props.documentId)

    return {
      canEdit,
      onDelete,
      documentStyle,
      emitShowDeleteDialog,
      hideEditingComponents,
      id_markdownHowTo,
      markdown,
      putPayload,
      toggleMarkdownHelp,
      showInfo,
      showInput,
    }
  },
}
</script>

<style scoped>
.document {
  text-align: left;
}

article {
  display: grid;
  grid-template-columns: 1fr 2em;
  padding: 0;
}

.actions {
  justify-content: space-evenly;
  height: min(90%, 6rem);
}

.danger {
  color: darkred;
}

.helpButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
</style>
