<template>
  <div class="image">
    <img :src="src" alt="">
  </div>
</template>

<script>
import {computed} from "@vue/reactivity";
import {useStore} from "vuex";

export default {
  name: "Image",
  props: {
    imageName: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const src = computed(() =>
        `https://raw.githubusercontent.com/centre-for-humanities-computing/websiteMedia/main/images/${store.state.serviceName}/${props.imageName}`
    )

    return {
      src
    }
  }
}
</script>

<style scoped>

</style>
