<template>
  <div
      :uk-toggle="config"
      @click="emitClick"
  >
    <slot></slot>
  </div>
</template>

<script>
import {computed} from "vue";

export default {
  name: "VOffCanvasButton",
  emits: ['click'],
  props: {
    offCanvasId: {
      type: String,
      required: true
    },
  },
  setup(props, {emit}) {
    const config = computed(() => `target: #${ props.offCanvasId }`)
    return {
      config,
      emitClick: () => emit('click'),
    }
  }
}
</script>

<style scoped>

</style>
