<template>
  <div class="assetStringAttribute">
    <v-text-input
        :editable="isEditable && showEditor"
        :value="value"
        class="uk-form-large"
        type="text"
        @blur="showEditor = false"
        @confirm="emitChange"
    />
    <h1
        v-if="!showEditor"
        @click="showEditor = isEditable"
    >
      <span class="value">
        {{ value }}
      </span>
    </h1>
  </div>
</template>

<script>
import VTextInput from "./vTextInput";
import {ref} from "vue";

export default {
  name: "VAssetStringAttribute",
  emits: ['change'],
  components: {VTextInput},
  props: {
    value: {
      type: String,
      default: 'no name'
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {
    const isDisabled = ref(!props.isEditable)
    const showEditor = ref(props.isEditing)
    return {
      emitChange(value) {
        emit('change', value)
        showEditor.value = false
      },
      isDisabled,
      showEditor,
    }
  }
}
</script>

<style scoped>

</style>
