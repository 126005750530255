<template>
  <div
      class="uk-button"
      :class="buttonClass"
      @click="emitClick"
  >
    <slot></slot>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'VButton',
  emits: ['click'],
  props: {
    buttonType: {
      default: 'default'
    },
    notAButton: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    return {
      buttonClass: computed(() => {
        let className = ''
        if (!props.notAButton) {
          className = `uk-button-${ props.buttonType }`
        }
        return className
      }),
      emitClick: () => emit('click'),
    }
  }
}
</script>

<style scoped>
</style>
