<template>
  <div
      :contenteditable="isEditable"
      :style="style"
      @input="update"
      @blur="update"
      @paste="onPaste"
      @keypress="onKeypress"
      ref="element"
  >{{content}}
  </div>
</template>

<script>
import {computed, ref, watch} from "vue";
import {useDarkmode} from "@/components/use/useDarkmode";

export default {
  name: "EditableDiv",
  props: {
    isEditable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'type here'
    },
    defaultValue: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
  },
  emits: [ 'change' ],
  setup(props, {emit} ){
    const element = ref(null)
    const initialValue = ref(props.value)
    const getContent = () => {
      let value = initialValue.value ?? props.defaultValue
      if (!value && props.isEditable) value = props.placeholder
      return sanitizeValue(value)
    }
    const { darkmode } = useDarkmode(props)
    const currentValue = () => element.value?.innerText
    const updateContent = value => {
      if (element.value?.innerText) element.value.innerText = value
    }
    const update = () => emit('change', currentValue())
    const onKeypress = event => event.key === 'Enter' && event.preventDefault()

    const sanitizeValue = value => {
      value = value.replaceAll(/\\r\\n/g, ' ')
      value = value.replaceAll(/\\n/g, ' ')
      value = value.replaceAll(/\\r/g, ' ')
      value = value.replaceAll(/<br>/g, '')
      value = value.trim()
      return value
    }

    const onPaste = event => {
      event.preventDefault()
      let text = (event.originalEvent || event).clipboardData.getData('text/plain')
      window.document.execCommand('insertText', false, sanitizeValue(text));
    }

    const style = computed(()=> {
      let style = {}
      if (props.isEditable) style = {
        border: `1px dotted ${darkmode.value ? 'white' : 'black'}`
      }
      return style
    })
    watch( () => props.value, newValue => {
      if (newValue !== undefined) {
        newValue = sanitizeValue(newValue)
        if (newValue !== currentValue()) {
          // updateContent(newValue)
        }
      }
    }, {immediate: true})
    return {
      element,
      onKeypress,
      onPaste,
      content: getContent(),
      style,
      update,
      updateContent,
    }
  },
  mounted() {
    this.updateContent(this.value || this.content)
  }
}
</script>

<style scoped lang="stylus">
div
  white-space pre-wrap
</style>
