<template>
  <div class="nameNewItemDialog">
    <div class="uk-card uk-card-default">
      <div class="uk-card-header">
        <h2 class="uk-header" v-html="heading"></h2>
      </div>
      <div class="uk-card-body">
        <div
            v-if="showImmutableNameWarning"
            class="uk-alert uk-alert-warning"
        >
          <em>{{ assetTypePlural }}</em> should not be renamed after creation. All references to this {{assetAlias}} are by text only and would need to be updated manually once the name is changed.
        </div>
        <p
            v-if="nameCollision"
            class="danger message p1"
        >
          Another <em>{{ assetAlias }}</em> with the name "{{ newName }}" already exists. Please find
          another name.
        </p>
        <vUrlSafeStringInput
            :escape="escape"
            :is-confirmed="!!newName"
            :is-disabled="isDisabled"
            :placeholder="placeholder"
            :show-char-range="true"
            @change="evaluateName"
        ></vUrlSafeStringInput>
      </div>
      <div class="uk-card-footer"></div>
    </div>

  </div>
</template>

<script>
import vUrlSafeStringInput from "@/components/utilities/vUrlSafeStringInput";
import {isArrayOfStrings} from "../../../../lib/getVariableType";
import {useNameNewItemFirst} from "@/components/use/useNameNewItemFirst";
import {computed, watch} from "vue";

export default {
  name: "NameNewDocumentDialog",
  emits: ['new-name-ready'],
  components: {
    vUrlSafeStringInput,
  },
  props: {
    assetPluralName: {
      type: String,
      default: 'Documents'
    },
    assetType: {
      type: String,
      default: 'Document',
      required: true
    },
    escape: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    showImmutableNameWarning: {
      type: Boolean,
      default: true
    },
    reservedNames: {
      type: Array,
      default: () => [],
      validate: isArrayOfStrings
    },
    takenNames: {
      type: Array,
      default: () => [],
      validate: isArrayOfStrings
    },
    title: {
      type: String,
      default: ''
    },
  },
  setup(props, {emit}) {
    const {
      assetAlias,
      assetTypePlural,
      evaluateName,
      nameCollision,
      newName,
      placeholder,
      nameInputIsVisible,
    } = useNameNewItemFirst(props)
    watch(newName, value => {
      emit('new-name-ready', value)
    })
    const heading = computed(() => props.title || `Create new <em>${ props.assetType }</em>`)
    return {
      assetAlias,
      assetTypePlural,
      evaluateName,
      heading,
      nameCollision,
      newName,
      placeholder,
      nameInputIsVisible,
    }
  }
}
</script>

<style scoped>

</style>
